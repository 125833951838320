import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/services/api.service';
import { ToastrService } from 'ngx-toastr';   
import { InputValidator } from 'src/services/validator';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  imeiForm: FormGroup;
  otpForm: FormGroup;
  warrantyList = [];
  @ViewChild('imei') imei: ElementRef;
  @ViewChild('phone') phone: ElementRef;
  otpSend: boolean = false;
  otpVerify: boolean = false;
  customerNumber: string = '';
  loader: boolean = false;
  inputLabel: string = 'IMEI/Serial number'
  placeholder: string = 'Enter IMEI/Serial number'
  checkedPhone: boolean = false;

  constructor( 
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.imeiPhoneForm(15, 20)
    this.otpSubmitForm(6)
  }

  imeiPhoneForm(minChar, maxChar) {
    this.imeiForm = this.formBuilder.group({
      imeiOrPhone: ['', [Validators.required, Validators.minLength(minChar), Validators.maxLength(maxChar), InputValidator.cannotContainSpace]]
    })
  }

  otpSubmitForm(minChar) {
    this.otpForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.minLength(minChar), Validators.maxLength(minChar), InputValidator.cannotContainSpace]]
    })
  }

  checkImeiBoxValue() {
    this.checkedPhone = false
    this.inputLabel = 'IMEI/Serial number';
    this.placeholder = 'Enter IMEI/Serial number'
    this.phone.nativeElement.checked = false
    this.imeiPhoneForm(15, 20)
  }

  checkPhoneBoxValue() {
    this.checkedPhone = true
    this.inputLabel = 'Registered phone number'
    this.placeholder = 'Enter registered phone number'
    this.imei.nativeElement.checked = false;
    this.imeiPhoneForm(10, 10)
  }

  sendOTP() {
    this.loader = true;
    let formValue = this.imeiForm.value
    let response;
    this.customerNumber = ''
    this.apiService.sendOTP(formValue.imeiOrPhone, this.checkedPhone).subscribe((res) => {
      response = res
    }, (error) => {
      this.otpSend = false;
      this.customerNumber = ''
      this.toastr.error("Oops. There was some problem")
      this.loader = false;
    }, () => {
      this.loader = false;
      if(response.status == 200) {
        this.toastr.success(response.message)
        this.otpSend = true
        this.customerNumber = response.data.customer_mobile
      } else {
        this.toastr.error(response.message)
      }
    })
  }

  verifyOTP() {
    this.loader = true;
    this.warrantyList = [];
    let formValue = this.otpForm.value
    let response;
    this.apiService.submitOTP(this.customerNumber, formValue.otp).subscribe((res) => {
      response = res
    }, (error) => {
      this.warrantyList = [];
      this.otpVerify = false
      this.toastr.error("Oops. There was some problem")
      this.loader = false;
    }, () => {
      this.loader = false;
      if(response.status == 200) {
        this.warrantyList = response['data']
        this.otpVerify = true
        this.toastr.success(response.message)
      } else {
        this.toastr.error("Invalid OTP")
      }
    })
  }

  downloadCertificate(url) {
    window.open(url, "_blank");
  }

  ngOnDestroy() {
    this.imeiPhoneForm(15, 20)
    this.otpSubmitForm(6)
    this.warrantyList = [];
    this.otpSend = false;
    this.otpVerify = false;
    this.customerNumber = ''
    this.inputLabel = 'IMEI/Serial number'
    this.placeholder = 'Enter IMEI/Serial number'
  }
}
