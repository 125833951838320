import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: "root"
})

export class ApiService {
    private url = environment

    constructor(private http: HttpClient) {}

    sendOTP(imeiOrPhone, checkedPhone) {
        let param = new HttpParams()
        param = checkedPhone ? param.append('phone', imeiOrPhone) : param.append('imeinumber', imeiOrPhone)
        return this.http.get(this.url.base + '/zopperassure/kit/verify_imei', {
            params: param
        })
    }

    submitOTP(phone, otp) {
        let header = new HttpHeaders({
            Authorization: 'Token ' + otp + '|' + phone
        })
        let param = new HttpParams()
        param = param.append('phone', phone)
        return this.http.get(this.url.base + '/zopperassure/kit/list-certificate', {
            headers: header, 
            params: param
        })
    }
}