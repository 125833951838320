<header>
    <div class="p-3 d-flex flex-column">
        <img  src="../assets/logo.svg" />
    </div>

</header>
<div class="ssp_container">
    <div class="ssp_row" *ngIf="!otpVerify">
        <div *ngIf="loader" class="loaderContainer">
            <div class="loader"></div>
        </div>
        <div class="warranty_form" *ngIf="!otpSend">
            <div class="check_row" >
                <input type="radio" name="imei" id="" checked #imei (change)="checkImeiBoxValue()" /> IMEI
                <input type="radio" name="phone" id="" #phone (change)="checkPhoneBoxValue()" /> Phone
            </div>
            <form [formGroup]="imeiForm" (ngSubmit)="sendOTP()">
                <div class="form_label">{{inputLabel}}</div>
                <div class="imei_form_container">
                    <input formControlName="imeiOrPhone" type="text" placeholder="{{placeholder}}">
                    <button type="submit" [disabled]="imeiForm.invalid">SEND OTP</button>
                </div>
            </form>
        </div>
        <div class="otp_form" *ngIf="otpSend">
            <form [formGroup]="otpForm" (ngSubmit)="verifyOTP()">
                <div class="otp_label">Please enter OTP sent to your mobile number</div>
                <input type="text" formControlName="otp">
                <div class="btns">
                    <button type="button" (click)="sendOTP()">Resend OTP</button>
                    <button type="submit" [disabled]="otpForm.invalid">Verify</button>
                </div>
            </form> 
        </div>
    </div>

    <div class="warranty_list" *ngIf="otpVerify">
        <div class="tableHeading">Download your Plan Certificate</div>
        <table>
            <thead>
                <tr>
                    <th>Plan Id</th>
                    <th>Product Serial Number/IMEI</th>
                    <th>Plan Certificate</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of warrantyList; let i = index">
                    <td>{{item['warranty_id']}}</td>
                    <td>{{item['item_obj']}}</td>
                    <td style="cursor: pointer; color: #eea58c;" (click)="downloadCertificate(item['certificate_url'])">Download</td>
                </tr>
                <tr *ngIf="warrantyList.length == 0">
                    <td colspan="3">No data found</td>
                </tr>
            </tbody>
        </table>
        <div class="bottom_link" (click)="ngOnDestroy()">Check another Plan detail</div>
    </div>
</div>
<footer>
    <div>Powered by</div>
    <div><img src="../assets/logo.svg" /></div>
</footer>
